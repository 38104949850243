<template>
  <div class="col-12 col-lg-6 c">
    <div class="card card-body">
      <h4 class="g">تسجيل الدخول عن طريق:</h4>
      <button
        class="btn btn-lg btn-relief-info"
        style="padding-top: 5px"
        v-b-modal.modal-1
      >
        <img
          :src="require('@/assets/images/logo/logo.png')"
          style="width: 30px; position: relative; top: 3px"
          alt=""
        />
        حسابك في التحضير الذكي</button
      ><br />
      <button class="btn btn-lg btn-primary" v-b-modal.modal-3>
        <i class="fa fa-user-plus"></i>
        إنشاء حساب جديد</button
      ><br />
      <button class="btn btn-lg btn-light" v-b-modal.modal-2>
        <i class="fa fa-sign-in"></i>
        تسجيل الدخول
      </button>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تسجيل الدخول عن طريق التحضير الذكي"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <b-card-text>
        <div class="form-group">
          <h5 for="">رقم الجوال</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="phone"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="password"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="tahdirLogin()">
            تسجيل الدخول
          </button>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      title="تسجيل الدخول"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <b-card-text>
        <div class="form-group">
          <h5 for="">رقم الجوال</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="phone"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="password"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="login()">تسجيل الدخول</button>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-3"
      title="انشاء حساب جديد"
      ok-only
      hide-footer
      ok-title="Accept"
    >
      <b-card-text>
        <div class="form-group">
          <h5 for="">الاسم</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="name"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="form-group">
          <h5 for="">رقم الجوال</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="phone"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input
            type="text"
            class="form-control form-control-lg"
            v-model="password"
            placeholder="اكتب هنا..."
          />
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="register()">
            انشاء الحسابة
          </button>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
  },
  data() {
    return {
      phone: null,
      password: null,
      name: null,
    };
  },
  methods: {
    tahdirLogin() {
      var g = this;
      if (g.phone && g.password) {
        $.post(api + "/client/auth/tahdir", {
          phone: g.phone,
          password: g.password,
        })
          .then(function (r) {
            if (r.status == 100) {
              localStorage.setItem("user", JSON.stringify(r.response));
              if (window.location.href.includes("backto")) {
                window.location = decodeURI(
                  window.location.href.split("backto=%2F")[1]
                ).replace("%2F", "/");
              } else {
                window.location = "/";
              }
            } else {
              alert("البيانات غير صحيحة");
            }
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      } else {
        alert("هناك حقول مطلوبة");
      }
    },
    login() {
      var g = this;
      if (g.phone && g.password) {
        $.post(api + "/client/auth/login", {
          phone: g.phone,
          password: g.password,
        })
          .then(function (r) {
            if (r.status == 100) {
              localStorage.setItem("user", JSON.stringify(r.response));
              if (window.location.href.includes("backto")) {
                window.location = decodeURI(
                  window.location.href.split("backto=%2F")[1]
                ).replace("%2F", "/");
              } else {
                window.location = "/";
              }
            } else {
              alert("البيانات غير صحيحة");
            }
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      } else {
        alert("هناك حقول مطلوبة");
      }
    },
    register() {
      var g = this;
      if (g.phone && g.password) {
        $.post(api + "/client/auth/register", {
          phone: g.phone,
          password: g.password,
          name: g.name,
        })
          .then(function (r) {
            if (r.status == 100) {
              localStorage.setItem("user", JSON.stringify(r.response));
              if (window.location.href.includes("backto")) {
                window.location = decodeURI(
                  window.location.href.split("backto=%2F")[1]
                ).replace("%2F", "/");
              } else {
                window.location = "/";
              }
            } else {
              alert("رقم الجوال مسجل مسبقاً");
            }
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      } else {
        alert("هناك حقول مطلوبة");
      }
    },
  },
};
</script>
